import {
    EvaluationConstants,
    CampaingHelpers,
    CampaignContants,
    UserSession,
    LayoutSources
} from 'olx-autos-landing-page';
import { SOURCE, VALUATION_FLOW_ROUTE } from '../constants/layout';

const getTabNameFromLoc = ({ location, config: { olxAutos = {}} = {}}) => {
    let tabName = '';
    const { TAB_NAME } = EvaluationConstants;

    if (
        location.pathname
        && location.pathname.includes(olxAutos?.urlKeywords?.sell)
    ) {
        tabName = TAB_NAME.SELL;
    }
    if (
        location.pathname
        && (location.pathname.includes(olxAutos?.urlKeywords?.buy) || location.pathname.includes(olxAutos?.urlKeywords?.buyPPC))
    ) {
        tabName = TAB_NAME.BUY;
    }
    if (
        location.pathname
        && (location.pathname.includes(olxAutos?.urlKeywords?.finance)
            || location.pathname.includes(olxAutos?.urlKeywords?.financePPC))
    ) {
        tabName = TAB_NAME.FINANCE;
    }
    return tabName;
};

export const shouldRequestViaBFF = ({ tabName, layoutSource = '', location, config }) => {
    const {
        TAB_NAME
    } = EvaluationConstants;
    const _tabName = tabName || getTabNameFromLoc({ location, config });

    const intendedTabName = _tabName === TAB_NAME.BUY
        || layoutSource === LayoutSources.BUYER_INTENT
        || layoutSource === LayoutSources.SELL_THEMATIC_PAGE;

    if (config?.landingPageRoutes?.['listing-letgo'] && intendedTabName) {
        return true;
    }

    return intendedTabName;
};

export const getUserContext = (
    { cookies, tabName: selectedTabName, isTabNameReq, config, location },
    layoutSource
) => {
    const userContext = {};
    const {
        USER_INTENT,
        EVALUATION_STATES,
        SELLER_STATE_COOKIE,
        TAB_NAME,
        USER_TAB_COOKIE
    } = EvaluationConstants;
    const userStateCookie = cookies && cookies[SELLER_STATE_COOKIE];
    const userTabCookie = cookies && cookies[USER_TAB_COOKIE];
    let tabName = getTabNameFromLoc({ location, config });
    const defaultLandingPage = config?.olxAutos?.defaultLandingPage;

    // TODO - Ashish - Will remove this once AB testing complete
    if (location?.hostname?.split('.')[0] === 'financiamiento') {
        tabName = TAB_NAME.FINANCE;
    }

    if (isTabNameReq && !tabName) {
        if (selectedTabName) {
            tabName = selectedTabName;
        }
        else if (
            userStateCookie === EVALUATION_STATES.VALUATION_DONE
            || userStateCookie === EVALUATION_STATES.APPOINTMENT_CREATED
            || userTabCookie === TAB_NAME.SELL
        ) {
            tabName = TAB_NAME.SELL;
        }
        else if (
            userTabCookie === TAB_NAME.BUY
            || userTabCookie === TAB_NAME.FINANCE
        ) {
            tabName = userTabCookie;
        }
        else if (defaultLandingPage) {
            tabName = defaultLandingPage;
        }
        else {
            tabName = TAB_NAME.SELL;
        }
    }

    if (tabName === TAB_NAME.BUY) {
        userContext[USER_INTENT.BUYER] = EVALUATION_STATES.INITIAL;
        userContext.tabName = tabName;
    }
    else if (tabName === TAB_NAME.FINANCE) {
        userContext[USER_INTENT.FINANCE] = EVALUATION_STATES.INITIAL;
        userContext.tabName = tabName;
    }
    else {
        userContext[USER_INTENT.SELLER]
            = userStateCookie || EVALUATION_STATES.INITIAL;
        if (tabName) {
            userContext.tabName = tabName;
        }
    }

    /***
     * tabName IS NOT REQUIRED FOR SOURCE WITH valuationFlow, layoutSource OLX or DefaultTab in Paid Session.
     * HENCE DELETING IT PRESENT IN userContext
     */
    if (
        (layoutSource === SOURCE.VALUATION_FLOW && userContext.tabName)
        || layoutSource === SOURCE.OLX
        || CampaingHelpers.getSessionShowAllTabs(location)
    ) {
        delete userContext.tabName;
    }
    return userContext;
};

export const getCXEQueryParams = ({ location, cookies, config: { olxAutos = {}} = {}, config }, tabName, layoutSource, seoInformation) => {
    const {
        REPEAT_USER_COOKIE_NAME,
        USER_VISIT_STATUS_LAYOUT
    } = EvaluationConstants;
    const queryStringObj = location && location.query;
    let channelOfAcquisition = CampaingHelpers.getChannelOfAcquisition(queryStringObj, config);
    const { CAMPAIGN_TYPE: { PAID }} = CampaignContants;
    const isPaidCampaign = !!channelOfAcquisition && channelOfAcquisition.type === PAID;
    const isTabNameReq = !(olxAutos?.tabViewNOTRequired); // TAB NAME IS NOT CONFIGURED FOR IN, in CXE, HENCE REMOVING IN FOR TAB NAME
    const userContext = getUserContext({ cookies, tabName, isTabNameReq, config, location }, layoutSource);
    const userVisit = cookies && cookies[REPEAT_USER_COOKIE_NAME] ? USER_VISIT_STATUS_LAYOUT.REPEAT : USER_VISIT_STATUS_LAYOUT.FIRST_TIME;
    const viaBFF = shouldRequestViaBFF({ tabName: userContext.tabName, layoutSource, config, location });
    const langs = config?.langs;
    const lang = langs && langs[0] && langs[0].smartlingLocaleCode;
    let additionalData = null;

    if (seoInformation) {
        channelOfAcquisition = { ...channelOfAcquisition, seoInformation: seoInformation.seoInfo };
        additionalData = seoInformation.additionalData;
    }

    return {
        channelOfAcquisition,
        userContext,
        userVisit,
        isPaidCampaign,
        viaBFF,
        lang,
        additionalData
    };
};

export const getLayoutSource = ({
    location = {},
    config: { buyerIntentRoute, landingPageRoutes = {}, sellThematicPages, olxAutos },
    params
}) => {
    let source = SOURCE.OLXAUTOS;
    const isValuationFlow = olxAutos?.isHandleSeoLink ? location.pathname.includes(VALUATION_FLOW_ROUTE) : location.pathname === VALUATION_FLOW_ROUTE;

    if (isValuationFlow) {
        source = SOURCE.VALUATION_FLOW;
        const { EVALUATION_STATES } = EvaluationConstants;
        const stateArray = [
            EVALUATION_STATES.VALUATION_STARTED,
            EVALUATION_STATES.INITIAL
        ];

        if (stateArray.indexOf(UserSession.getUserEvaluationState()) === -1) {
            source = SOURCE.OLXAUTOS;
        }
    }
    if (landingPageRoutes?.['listing-letgo']) {
        source = SOURCE.LETGO_AUTOS;
    }
    if (buyerIntentRoute && location.pathname === `/${buyerIntentRoute}`) {
        source = SOURCE.BUYER_INTENT;
    }
    if (params?.seoTheme && sellThematicPages?.showSellThematicPages) {
        source = SOURCE.SELL_THEMATIC_PAGE;
    }
    return source;
};

